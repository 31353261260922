window.grid ||= {}

grid.location_map =
  api_key: "AIzaSyAXTs9TIDYqveAKE6kBdXMBvesXMb1uvl4"
  map_elements: document.querySelectorAll('.location_map_element')
  polyline: [
    { lng: -4.277182420999964, lat: 55.87121212100004 },
    { lng: -4.278905946999942, lat: 55.87271562200004 },
    { lng: -4.279414508999935, lat: 55.87298330200008 },
    { lng: -4.279796419999968, lat: 55.87324622200003 },
    { lng: -4.27978823199993, lat: 55.87353768800006 },
    { lng: -4.279743710999981, lat: 55.87384506200004 },
    { lng: -4.279412820999937, lat: 55.87429356000007 },
    { lng: -4.279195266999977, lat: 55.87456522900004 },
    { lng: -4.281899184999929, lat: 55.87544397600004 },
    { lng: -4.283539989999952, lat: 55.87593387800007 },
    { lng: -4.288723439999956, lat: 55.87749236400003 },
    { lng: -4.289901229999941, lat: 55.87788695600005 },
    { lng: -4.290374253999971, lat: 55.87771489100004 },
    { lng: -4.290777719999937, lat: 55.87748893900005 },
    { lng: -4.291727675999937, lat: 55.87694619600006 },
    { lng: -4.292473048999966, lat: 55.87635639100006 },
    { lng: -4.294695483999931, lat: 55.87435842200006 },
    { lng: -4.29717444299996, lat: 55.87225533500003 },
    { lng: -4.299351934999947, lat: 55.87054343300008 },
    { lng: -4.300760278999974, lat: 55.87080697000005 },
    { lng: -4.301855164999949, lat: 55.87084307400005 },
    { lng: -4.307717873999934, lat: 55.87078171900004 },
    { lng: -4.311295519999931, lat: 55.87073879300004 },
    { lng: -4.315426275999926, lat: 55.87071067200003 },
    { lng: -4.318772382999953, lat: 55.87067475700007 },
    { lng: -4.32017252299994, lat: 55.87069175200003 },
    { lng: -4.321921604999943, lat: 55.87092619500004 },
    { lng: -4.322449592999931, lat: 55.87117812500003 },
    { lng: -4.322898720999945, lat: 55.87117650800008 },
    { lng: -4.323293819999947, lat: 55.87106520900005 },
    { lng: -4.323848701999964, lat: 55.87125463900003 },
    { lng: -4.325158955999939, lat: 55.87173370600004 },
    { lng: -4.327348320999931, lat: 55.87280561100005 },
    { lng: -4.328500128999963, lat: 55.87333225200007 },
    { lng: -4.330104699999936, lat: 55.86998992800005 },
    { lng: -4.330718797999964, lat: 55.86866436000008 },
    { lng: -4.330974983999965, lat: 55.86815936400006 },
    { lng: -4.331578349999972, lat: 55.86816488000005 },
    { lng: -4.333542593999937, lat: 55.86823782500005 },
    { lng: -4.335653905999948, lat: 55.86832990700003 },
    { lng: -4.336593271999959, lat: 55.86833794700004 },
    { lng: -4.337022605999948, lat: 55.86828321100006 },
    { lng: -4.337258238999937, lat: 55.86822180300004 },
    { lng: -4.337540026999932, lat: 55.86800942300005 },
    { lng: -4.337637607999966, lat: 55.86702937100006 },
    { lng: -4.338376358999938, lat: 55.86614437200006 },
    { lng: -4.338599085999931, lat: 55.86594968400004 },
    { lng: -4.338850581999964, lat: 55.86585690700008 },
    { lng: -4.339048379999952, lat: 55.86569311500006 },
    { lng: -4.341903696999964, lat: 55.86644453700006 },
    { lng: -4.344148218999976, lat: 55.86706928200005 },
    { lng: -4.345745467999961, lat: 55.86517742400008 },
    { lng: -4.346955842999932, lat: 55.86352949200005 },
    { lng: -4.349836626999945, lat: 55.86117156600005 },
    { lng: -4.350180378999937, lat: 55.86091990200003 },
    { lng: -4.349801670999966, lat: 55.86063494900003 },
    { lng: -4.349645971999962, lat: 55.86051775900006 },
    { lng: -4.348825423999926, lat: 55.86009528400007 },
    { lng: -4.347969986999942, lat: 55.85975903200006 },
    { lng: -4.34793420099993, lat: 55.85974596400007 },
    { lng: -4.345940410999958, lat: 55.85902113600008 },
    { lng: -4.345392009999955, lat: 55.85881759800003 },
    { lng: -4.341978775999962, lat: 55.85754986000006 },
    { lng: -4.341438609999955, lat: 55.85735667200004 },
    { lng: -4.341072580999935, lat: 55.85722576100005 },
    { lng: -4.340539810999928, lat: 55.85703943100003 },
    { lng: -4.340169045999971, lat: 55.85692986600003 },
    { lng: -4.339746200999969, lat: 55.85749718500006 },
    { lng: -4.339267207999967, lat: 55.85813980600005 },
    { lng: -4.33894943599995, lat: 55.85856628700003 },
    { lng: -4.338524732999929, lat: 55.85913608400006 },
    { lng: -4.337836038999967, lat: 55.86005997500007 },
    { lng: -4.336217185999942, lat: 55.86223174700007 },
    { lng: -4.335748290999959, lat: 55.86259386200004 },
    { lng: -4.335109899999964, lat: 55.86284010700007 },
    { lng: -4.334387094999954, lat: 55.86296077700007 },
    { lng: -4.333870710999975, lat: 55.86297920300007 },
    { lng: -4.333001086999957, lat: 55.86303425800003 },
    { lng: -4.332579707999969, lat: 55.86313662100008 },
    { lng: -4.331948777999969, lat: 55.86340922800008 },
    { lng: -4.331472539999936, lat: 55.86386314200007 },
    { lng: -4.331353701999944, lat: 55.86425644400003 },
    { lng: -4.329620483999975, lat: 55.86417033300006 },
    { lng: -4.325992937999956, lat: 55.86405295200007 },
    { lng: -4.323312785999974, lat: 55.86402322000004 },
    { lng: -4.320560444999956, lat: 55.86396162700004 },
    { lng: -4.317587534999973, lat: 55.86392372000006 },
    { lng: -4.317508873999941, lat: 55.86372966900007 },
    { lng: -4.317366568999944, lat: 55.86328639600003 },
    { lng: -4.317326647999948, lat: 55.86304854400004 },
    { lng: -4.317303791999962, lat: 55.86266476800006 },
    { lng: -4.317236206999951, lat: 55.86244039600007 },
    { lng: -4.317130372999941, lat: 55.86230212400005 },
    { lng: -4.316874703999929, lat: 55.86219716900007 },
    { lng: -4.316578971999945, lat: 55.86214785400006 },
    { lng: -4.316147500999932, lat: 55.86218647400005 },
    { lng: -4.315514570999937, lat: 55.86230206700003 },
    { lng: -4.313900183999976, lat: 55.86214340000004 },
    { lng: -4.312540740999964, lat: 55.86207749500005 },
    { lng: -4.311398303999965, lat: 55.86201359400008 },
    { lng: -4.310432440999932, lat: 55.86200125100004 },
    { lng: -4.310032738999951, lat: 55.86202705600004 },
    { lng: -4.309635691999972, lat: 55.86211405200004 },
    { lng: -4.308002914999975, lat: 55.86165434000003 },
    { lng: -4.308233320999932, lat: 55.86122670400005 },
    { lng: -4.30855388599997, lat: 55.86062085000003 },
    { lng: -4.309061337999935, lat: 55.85978734800005 },
    { lng: -4.309507626999959, lat: 55.85940052400008 },
    { lng: -4.309578600999942, lat: 55.85859919000006 },
    { lng: -4.308487646999936, lat: 55.85836064300008 },
    { lng: -4.306712890999961, lat: 55.85792828200005 },
    { lng: -4.304960445999939, lat: 55.85750164500007 },
    { lng: -4.303019452999933, lat: 55.85703842500004 },
    { lng: -4.302824662999967, lat: 55.85698964800008 },
    { lng: -4.302727545999971, lat: 55.85692362100008 },
    { lng: -4.30265580799994, lat: 55.85682319600005 },
    { lng: -4.299761974999967, lat: 55.85625132600006 },
    { lng: -4.299742892999973, lat: 55.85607339600006 },
    { lng: -4.299668769999926, lat: 55.85583385500007 },
    { lng: -4.299460859999954, lat: 55.85564981800007 },
    { lng: -4.290829547999977, lat: 55.85384919200004 },
    { lng: -4.290130628999975, lat: 55.85371110100004 },
    { lng: -4.289510014999962, lat: 55.85359930400006 },
    { lng: -4.288917611999977, lat: 55.85368742900005 },
    { lng: -4.288558659999978, lat: 55.85382980100007 },
    { lng: -4.28835268499995, lat: 55.85396315700007 },
    { lng: -4.288215503999936, lat: 55.85414765700006 },
    { lng: -4.287908453999933, lat: 55.85576965400003 },
    { lng: -4.287827295999932, lat: 55.85605814100006 },
    { lng: -4.287760122999941, lat: 55.85625071000004 },
    { lng: -4.287507615999971, lat: 55.85640625400004 },
    { lng: -4.287283123999941, lat: 55.85647504500008 },
    { lng: -4.286520527999926, lat: 55.85636850400005 },
    { lng: -4.28507297699997, lat: 55.85608497900006 },
    { lng: -4.282026650999967, lat: 55.85746950500004 },
    { lng: -4.28163187499996, lat: 55.85779946100007 },
    { lng: -4.281598434999978, lat: 55.85793533700007 },
    { lng: -4.281397003999928, lat: 55.85875378500003 },
    { lng: -4.281347058999927, lat: 55.85897849700007 },
    { lng: -4.281339465999963, lat: 55.85901252100007 },
    { lng: -4.281322011999976, lat: 55.85909094500005 },
    { lng: -4.281252013999961, lat: 55.85922166200004 },
    { lng: -4.28122198899996, lat: 55.85937185800003 },
    { lng: -4.281203306999942, lat: 55.85952202400006 },
    { lng: -4.281197194999947, lat: 55.85957040000005 },
    { lng: -4.281151337999972, lat: 55.85980289900004 },
    { lng: -4.281116262999944, lat: 55.85998073700006 },
    { lng: -4.281052824999961, lat: 55.86015107600008 },
    { lng: -4.280937712999958, lat: 55.86036922600005 },
    { lng: -4.280244083999946, lat: 55.86025404800006 },
    { lng: -4.279400791999933, lat: 55.86017534600006 },
    { lng: -4.278890542999932, lat: 55.86014575100006 },
    { lng: -4.278506481999955, lat: 55.86015468500005 },
    { lng: -4.278419467999925, lat: 55.86034082700007 },
    { lng: -4.278422309999939, lat: 55.86046473100004 },
    { lng: -4.278495523999936, lat: 55.86108653600007 },
    { lng: -4.278610741999955, lat: 55.86227441200003 },
    { lng: -4.278321459999972, lat: 55.86300257000005 },
    { lng: -4.273419739999952, lat: 55.86308893200004 },
    { lng: -4.270769568999981, lat: 55.86306198500006 },
    { lng: -4.271535637999932, lat: 55.86595302000006 },
    { lng: -4.271436304999952, lat: 55.86687399300007 },
    { lng: -4.27114721199996, lat: 55.86744393200007 },
    { lng: -4.271640299999945, lat: 55.86771456200006 },
    { lng: -4.272917854999946, lat: 55.86847738700004 },
    { lng: -4.275003362999939, lat: 55.86968055900007 },
    { lng: -4.276553968999963, lat: 55.87054694800003 },
    { lng: -4.276700623999943, lat: 55.87069427500006 },
    { lng: -4.277182420999964, lat: 55.87121212100004 }
  ]
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -10
        },
        {
          "lightness": 70
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#003865"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -40
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": 80
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -40
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 55
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": 55
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -40
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": 90
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": 55
        },
        {
          "weight": 2
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": -15
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -65
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "lightness": 90
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#00b5d1"
        },
        {
          "saturation": -40
        },
        {
          "lightness": 20
        }
      ]
    }
  ]

  setup: ->
    if grid.location_map.map_elements.length == 0
      return false

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{grid.location_map.api_key}&loading=async&callback=grid.location_map.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        grid.location_map.initialize()
    )(document,"script","gmap_loader")

    @add_listeners()

  add_listeners: ->
    url = window.location.search
    params = new URLSearchParams(url)
    if params.get('filter') && params.get('filter').length
      document.querySelector('body').classList.add('is_filtered')

    filter_link = document.querySelector('.location_filter_link')
    if filter_link
      filter_link.addEventListener 'click', (e) ->
        e.preventDefault()
        document.querySelector('.location_filter_wrapper').classList.add('open')

    close_link = document.querySelector('.location_filter_close_link')
    if close_link
      close_link.addEventListener 'click', (e) ->
        e.preventDefault()
        document.querySelector('.location_filter_wrapper').classList.remove('open')

  initialize: ->
    @setup_maps()

  setup_maps: ->
    grid.location_map.map_elements.forEach (location, idx) ->
      pin = location.getAttribute('data-icon')
      cluster_pin = location.getAttribute('data-cluster-icon')
      map = grid.location_map.create_map(location)
      bounds = new google.maps.LatLngBounds()


      lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4
      }

      outline = new google.maps.Polyline({
        path: grid.location_map.polyline,
        geodesic: true,
        strokeOpacity: 0,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '20px'
        }],
        map: map
      })


      oms = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        circleFootSeparation: 30
      })

      document.querySelectorAll('.location_stub_item').forEach (stub, idx) ->
        return unless stub.getAttribute('data-lat') && stub.getAttribute('data-lng')

        colour = stub.getAttribute('data-colour')
        unless colour
          colour = '#00b5d1'
        map_pin = window.btoa(pin.replace('#00b5d1', colour))
        marker = new google.maps.Marker({
          position: { lat: parseFloat(stub.getAttribute('data-lat')), lng: parseFloat(stub.getAttribute('data-lng')) },
          icon:
            url: "data:image/svg+xml;base64,#{map_pin}",
            scaledSize: new google.maps.Size(42, 34.15)
          map: map
        })

        stub.querySelector('.location_stub_close_link').addEventListener 'click', (e) ->
          e.preventDefault()
          stub.style.display = 'none'
          document.querySelector('body').classList.remove('show_location')

        marker.addListener 'spider_click', ->
          document.querySelectorAll('.location_stub_item').forEach (stub) ->
            stub.style.display = 'none'

          stub.style.display = 'flex'

          document.querySelector('body').classList.add('show_location')

        marker.addListener 'spider_format', (status) ->
          if status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
            new_pin = window.btoa(cluster_pin)
          else
            new_pin = map_pin
          marker.setIcon({
            url: "data:image/svg+xml;base64,#{new_pin}",
            scaledSize: new google.maps.Size(42, 34.15)
          })

        oms.addMarker(marker)
        bounds.extend(marker.getPosition())

      map.fitBounds(bounds)

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      mapTypeId: google.maps.MapTypeId.ROADMAP
      maxZoom: 18
      draggable: true
      zoomControl: false
      cameraControl: false
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false
      styles: grid.location_map.styles

    if location.getAttribute('data-autozoom')
      options['scrollwheel'] = true

    if window.matchMedia('(max-width: 849px)').matches
      options['cameraControl'] = false
    else
      options['cameraControl'] = true

    new google.maps.Map(location, options)
